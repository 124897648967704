import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BMXFetch from "../../../Utils/GlobalFetch/GlobalFetch";
import { deleteCredentials } from "./utils";
import i18n from "./../../../Utils/i18next/i18next";

const initialState = {
  user: {},
  loading: false,
};

export const login = createAsyncThunk("login", async (data) => {
  try {
    const res = await new BMXFetch("/auth", {
      method: "POST",
    })
      .setBody(data)
      .send();
    return res.data;
  } catch (err) {
    switch (err?.response?.status) {
      case 401:
      case 403:
        //eslint-disable-next-line
        // throw i18n.t("global:invalid_credentials");
        throw i18n.t(
          `global:${err?.response?.data?.message ?? "invalid_credentials"}`
        );
      default:
        //eslint-disable-next-line
        throw i18n.t("global:login_failed");
    }
  }
});
export const loginChange = createAsyncThunk("login", async (data) => {
  try {
    const res = await new BMXFetch("/auth/changePassword", {
      method: "POST",
    })
      .setBody(data)
      .send();
    return res.data;
  } catch (err) {
    switch (err?.response?.status) {
      case 401:
      case 403:
        //eslint-disable-next-line
        throw i18n.t(`global:${err?.response?.data?.message}`);
      default:
        //eslint-disable-next-line
        throw i18n.t("global:login_failed");
    }
  }
});

const userSesstionReducer = createSlice({
  name: "userSessionReducer",
  initialState,
  reducers: {
    unregisterUser: (state) => {
      deleteCredentials();
      state.user = {};
    },
    setUser: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user = payload;
    },
    [login.rejected]: (state) => {
      state.loading = false;
      deleteCredentials();
    },
  },
});

export const { unregisterUser, setUser } = userSesstionReducer.actions;

export default userSesstionReducer.reducer;
