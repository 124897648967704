import TextField from "@mui/material/TextField";
import "./BMXInputFile.scss";
import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";
import { Icon, IconButton } from "@mui/material";
import BMXChip from "./../../../Components/Chip/BMXChip";
import { useTranslation } from "react-i18next";

const BMXInputFile = (props) => {
  const { t } = useTranslation();
  const isError = () => {
    const {
      meta: { touched, error, submitError },
    } = props;
    return !!((touched && error) || submitError);
  };
  const {
    className,
    meta: { error, submitError },
    afterIcon,
    readOnly,
    beforeIcon,
    form,
    input: { name },
  } = props;


  const [value, setValue] = React.useState();
  const [weight, setFileWeight] = React.useState();
  const fileInput = useRef();
  const textInput = useRef();

  useEffect(() => {
    form.change(name, value);
    // eslint-disable-next-line
  }, [value]);

  const files = (e) => {
    let number = 0;
    for (let i = 0; i < e.length; i++) {
      number = number + e[i].size;
    }
    setFileWeight(number);
  };
  const valueWeight = (weight) => {
    if (!weight) {
      return 0.0 + " kB";
    }
    if (weight > 1048576) return (weight / 1024 / 1024).toFixed(2) + "MB";
    else return (weight / 1024).toFixed(2) + "kB";
  };
  const onFileChange = (e) => {
    setValue(e.target.files);
    files(e.target.files);
    if(e?.target?.files?.length > 0){
      form.resetFieldState(name);
      if(props?.resetHeader){
        props.resetHeader();
      }
    }
  };

  const clearValue = () => {
    setValue(null);
    files(0);
    const timer = setTimeout(()=>{
      form.resetFieldState(name);
      clearTimeout(timer);
    },100);    
  };

  const chip = (
    <div>
      {value && value[0] && (
        <div>
          <BMXChip
            size={"small"}
            label={`${value[0].name} (${(value[0].size / 1024).toFixed(1)} kB)`}
            font={"white"}
            backgroundColor={"#445d75"}
          />
          {value.length > 1 ? `+${value.length - 1}` : ""}
        </div>
      )}
    </div>
  );
  const handle = (e) => {
    fileInput.current.click();
    e.preventDefault();
  };

  return (
    <div className={`BMXInputFile ${className || ""}`}>
      <div className="IconArea">
        {beforeIcon && <Icon fontSize="medium">{beforeIcon}</Icon>}
        <TextField
          id="standard-basic"
          variant="standard"
          ref={textInput}
          onMouseDown={handle}
          error={isError()}
          InputProps={{
            readOnly: readOnly,
            startAdornment: (
              <div className="inputValue">{value ? <div>{chip}</div> : ""}</div>
            ),
          }}
          helperText={isError() ? error || submitError : ""}
          {...props}
        />
        {afterIcon && (
          <IconButton size="small" onClick={clearValue}>
            <Icon fontSize="medium">{afterIcon}</Icon>
          </IconButton>
        )}
      </div>
      <div className="InputFileMessage">
        <span>
          {t("global:filesNumber.lenght")} {(value && value.length) || 0} (
          {t("global:filesNumber.weight")} {valueWeight(weight)})
        </span>
      </div>
      <input
        id="file"
        multiple
        type="file"
        style={{ display: "none" }}
        accept="*"
        ref={fileInput}
        onChange={onFileChange}
      />
    </div>
  );
};

export default BMXInputFile;

BMXInputFile.propTypes = {
  /**
      Props zawiera kolor czcionki 
    */
  font_color: PropTypes.string,
  /**
    Props zawiera informacje czy pole jest aktywne czy nie
  */
  readOnly: PropTypes.bool,
  /**
    Props zawiera nazwe pola
  */
  label: PropTypes.string,
  /**
    Props zawiera nazwę klasy która pozwala nam np modyfikować style css
  */
  className: PropTypes.string,
  /**
    Props określa czy pole jest checkboxem
  */
  fieldtype: PropTypes.string,
};
