export const EmailValidators = (value) => {
  // eslint-disable-next-line
  const gex = new RegExp("[a-z0-9]+@[a-z0-9]+.[a-z]{2,3}");
  return !gex.test(value);
};

export const PhoneValidators = (value) => {
  /* eslint-disable */
  const gex =
    /^[\+]?[0-9]{0,2}[-\s\.]?[0-9]{3}?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3}$/;
  return !gex.test(value);
  /* eslint-disable */
};

export const passwordValidator = (value) => {
  // eslint-disable-next-line
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{12,}$/;
  return passwordRegex.test(value);
};
