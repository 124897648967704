import i18n from "../../../../Utils/i18next/i18next";
import store from "../../../../store";
import { NotificationManager } from "react-notifications";
import {
  EmailValidators,
  passwordValidator,
  PhoneValidators,
} from "../../../../Utils/Validators/Validators";
import { cloneDeep, set } from "lodash";
import { GlobalAuthFetch } from "../../../../Utils/GlobalAuthFetch/GlobalAuthFetch";
const { t } = i18n;

export const onSubmit = async (values, props) => {
  const { workerReducer } = store.getState();
  const { updateTableRegistry, loadData, update, updateSingleRegistry } = props;
  const data = cloneDeep(values);
  set(data, "position.uuid", values?.position?.value);
  set(data, "isAdmin", values?.isAdmin?.value);
  set(
    data,
    "roles",
    values?.roles?.map((el) => ({ uuid: el.value }))
  );

  let payload;

  if (!update) {
    set(
      data,
      "status",
      workerReducer?.availableStatuses.find((el) => !!el.isSelected)
    );

    const res = await GlobalAuthFetch({
      path: `/worker`,
      method: "POST",
      data,
    });
    payload = res.payload;
  }

  if (update) {
    const res = await GlobalAuthFetch({
      path: `/worker`,
      method: "PATCH",
      data,
    });
    payload = res.payload;
  }

  if (payload?.status >= 300 || !payload?.status) {
    props.setErrorHeader(0, true);
    props.setErrorHeader(1, true);

    return;
  }

  props.setErrorHeader(0, false);
  props.setErrorHeader(1, false);

  //Succes nofify
  loadData();
  NotificationManager.success(i18n.t("global:success"));
  props.handleHide();
  if (updateSingleRegistry) updateSingleRegistry();
  if (updateTableRegistry) updateTableRegistry();
};

export const onValidate = (values, props) => {
  // const {update} = props;
  const errorsStep0 = {};
  const errorsStep1 = {};

  if (!values.firstName) {
    errorsStep0.firstName = t("global:worker.errors.firstName");
  }
  if (!values.lastName) {
    errorsStep0.lastName = t("global:worker.errors.lastName");
  }
  if (!values.position) {
    errorsStep0.position = t("global:worker.errors.position");
  }

  if (values.privateMail && EmailValidators(values.privateMail)) {
    errorsStep0.privateMail = t("global:worker.errors.mailNotValid");
  }

  if (EmailValidators(values.mail)) {
    errorsStep0.mail = t("global:worker.errors.mailNotValid");
  }

  if (!values.mail) {
    errorsStep0.mail = t("global:worker.errors.mail");
  }

  if (values.privatePhone && PhoneValidators(values.privatePhone)) {
    errorsStep0.privatePhone = t("global:worker.errors.phoneNotValid");
  }

  if (PhoneValidators(values.phone)) {
    errorsStep0.phone = t("global:worker.errors.phoneNotValid");
  }

  if (!values.phone) {
    errorsStep0.phone = t("global:worker.errors.phone");
  }

  if (!values.roles || values.roles?.length <= 0) {
    errorsStep1.roles = t("global:worker.errors.roles");
  }

  if (values.isUser && !values.login) {
    errorsStep1.login = t("global:worker.errors.login");
  }

  if (
    (values?.loggedWorker?.admin || values?.loggedWorker?.superAdmin) &&
    values.isUser && !props.update &&
    !values.password
  ) {
    errorsStep1.password = t("global:worker.errors.password");
  }

  if (
    (values?.loggedWorker?.admin || values?.loggedWorker?.superAdmin) &&
    values.isUser && !props.update &&
    !values.passwordRepeat
  ) {
    errorsStep1.passwordRepeat = t("global:worker.errors.passwordRepeat");
  }

  if(values.passwordRepeat!==values.password){
    errorsStep1.password = t("global:worker.errors.passwordNotEqual");
    errorsStep1.passwordRepeat = t("global:worker.errors.passwordNotEqual");
  }

  if (!passwordValidator(values.password)) {
    errorsStep1.password = t("global:worker.errors.passwordNotValid");
    errorsStep1.passwordRepeat = t("global:worker.errors.passwordNotValid");
  }

  // if (
  //   values.isUser &&
  //   values.password &&
  //   values.passwordRepeat &&
  //   values.password !== values.passwordRepeat
  // ) {
  //   errorsStep1.passwordRepeat = t("global:worker.errors.notEqual");
  //   errorsStep1.password = t("global:worker.errors.notEqual");
  // }

  if (
    !props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length > 0
  ) {
    props.setErrorHeader(0, true);
  }
  if (
    !!props.steps[0]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep0).length <= 0
  ) {
    props.setErrorHeader(0, false);
  }
  if (
    !props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length > 0
  ) {
    props.setErrorHeader(1, true);
  }
  if (
    !!props.steps[1]?.error &&
    props.isSubmit &&
    Object.keys(errorsStep1).length <= 0
  ) {
    props.setErrorHeader(1, false);
  }
  return { ...errorsStep0, ...errorsStep1 };
};
