import { Form } from "react-final-form";
import { onSubmit, onValidate } from "./WorkerFormSubmit";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@emotion/react";
import WorkerFormStepTwo from "./WorkerFormSteps/WorkerFormStepTwo";
import WorkerFormStepOne from "./WorkerFormSteps/WorkerFormStepOne";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const WorkerForm = (props) => {
  const theme = useTheme();
  const { step, setStep, update } = props;
  const [initialValues, setInitialValues] = useState(null);
  const loggedWorker = useSelector((state) => state?.userSession?.user);

  useEffect(() => {
    if (update) {
      setInitialValues({
        ...props.initialValues,
        position: { value: props.initialValues?.position?.uuid },
        initRoles: props.initialValues?.roles,
        isUser: props.initialValues.user,
        isAdmin: { value: props.initialValues.admin },
        loggedWorker:loggedWorker,
        password:null
      });
      return;
    }

    setInitialValues({
      isAdmin: { value: false },
      loggedWorker:loggedWorker,
      password:null
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(val) => onSubmit(val, props)}
      validate={(val) => onValidate(val, props)}
      validateOnBlur={true}
    >
      {(formProps) => {
        return (
          <form
            id={props.formName}
            onSubmit={formProps.handleSubmit}
            className="WorkerForm"
          >
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={step}
              onChangeIndex={(index) => setStep(index)}
            >
              <WorkerFormStepOne {...props} {...formProps}  />
              <WorkerFormStepTwo {...props} {...formProps} />
            </SwipeableViews>
          </form>
        );
      }}
    </Form>
  );
};

export default WorkerForm;
